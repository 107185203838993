import React from 'react';
import { graphql, Link } from 'gatsby';
import get from 'lodash.get';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from 'components/Layout';

import useSiteMetadata from 'hooks/useSiteMetadata';

import * as styles from './blog.module.css';

export const query = graphql`
  query NewsQuery($slug: String!) {
    contentfulNews(slug: { eq: $slug }) {
      slug
      title
    }
  }
`;

const NewsPage = ({ data, location }) => {
  const { siteUrl } = useSiteMetadata();

  const news = get(data, `contentfulNews`, {});

  const canonical = `${siteUrl}${location.pathname}`;

  return (
    <Layout location={location}>
      <GatsbySeo
        title={news.title}
        canonical={canonical}
        metaTags={[{ name: 'keywords', content: ['News'] }]}
        description="Richardson Shop News"
        openGraph={{
          title: news.title,
          url: canonical,
          description: 'Richardson Shop News',
        }}
      />

      <h1 className={styles.title}>{news.title}</h1>

      <Link to={`/${news.slug}/latest/`}>
        <h2>Latest</h2>
      </Link>
      <Link to={`/${news.slug}/archive/`}>
        <h2>Archive</h2>
      </Link>
    </Layout>
  );
};

export default NewsPage;
